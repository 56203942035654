<template>
    <modal-lateral ref="modalCrearCombo" titulo="Buscar producto para añadir">
        <div class="row mx-0 justify-center my-3">
            <div class="col-10">
                <input-productos ref="input_productos" v-model="buscar" :adicional="adicional" :adicional_not="adicional_not" @productosObtenidos="setProductos" />
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 137px)">
            <div class="row mx-0 justify-center"  id="loading-agregar-producto">
                <div class="col-10 px-0">
                    <div v-for="producto, index in productos" :key="index" class="d-flex">
                        <div class="col-auto px-0">
                            <img :src="producto.imagen" width="89" height="89" class="obj-cover" />
                        </div>
                        <div class="col">
                            <p class="text-general f-14 nombre f-500">
                                {{producto.nombre}}
                            </p>
                            <p class="text-general2 f-14 my-1">
                                {{producto.presentacion}}
                            </p>                            
                        </div>
                        <div class="col-auto d-middle-center px-0">
                            <div class="bg-general text-white br-5 cr-pointer">
                                <i class="icon-plus f-17" @click="agregarProducto(producto.id)"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import Productos from '~/services/productos/productos'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            buscar: '',
            img_producto: 'https://www.las2orillas.co/wp-content/uploads/2021/04/biscolata-600x600.jpeg',
            productos : [],
            id_producto_padre : null,
            adicional: [
                {
                    campo : 'kit',
                    valor : 0
                }
            ],
            adicional_not: []
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
            cedis:'cedis/cedis',
        }),
        
    },
    methods: {
        toggle(id_producto){
            this.id_producto_padre = id_producto            
            this.$refs.modalCrearCombo.toggle();
        },
        setProductos(productos){
            this.productos = productos
        },
        async agregarProducto(id_producto){
            try {                
                let loading = this.$loading(
                {
                    lock: true,
                    target:`#loading-agregar-producto`
                });
                
                let datos = {
                    id_producto_padre : this.id_producto_padre,
                    id_producto_hijo : id_producto
                }

                const { data } = await Productos.AgregarProductoKit(datos)
                this.$refs.input_productos.buscarProductos()
                this.$emit('recargarProductos')
                this.notificacion('Éxito', "Se ha agregado el producto al kit", 'success')
                loading.close()
                
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>

<style>

</style>